<template>
  <v-hover v-slot="{hover}">
      <div class="pt-4" v-if="section.isHtml" >

        <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <!-- Drag Icon-->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet color="transparent" width="32" class="editor-section-drag">
              <v-icon v-if="hover"  style="cursor: grab"  :color="wsACCENT">
                mdi-drag-vertical
              </v-icon>
            </v-sheet>
          </v-col>
          <!-- Section Content -->
          <v-col style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">

            <!-- Image -->
            <Editor
                v-model="section.text"
                :style="editorStyle"
                :api-key="TINY_MCE"
                :init="editorOptions"
                class="section_paragraph"/>

          </v-col>
          <!-- Section Edit Buttons -->
          <v-col style=" height: 100% !important;" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <v-sheet width="32" color="transparent" class="pl-1 fill-height " >
              <ft-select
                  @input="$emit('edit',$event)"
                  :items="editActionsSelect">
                <ws-icon-button v-if="hover" small   />
              </ft-select>
            </v-sheet>
          </v-col>

        </v-row>

      </div>
  </v-hover>

</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "editorSectionHtml",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    placeholder : {
      type : String
    }
  },
  components : {
    Editor,
  },
  data() {
    return {
      editorStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 14px;\n' +
          '  font-weight: 500 !important; \n' +
          '  line-height: 26px;\n' +
          '  margin : 0;\n' +
          '  outline : none; ',
    }
  },
  computed : {

    editorOptions() {
      return {
        selector: '.section_paragraph',
        menubar: false,
        inline: true,
        plugins: [
          'autolink', 'codesample', 'link', 'lists',
          'table', 'hr',
          'quickbars', 'codesample', 'help'
        ],
        placeholder: this.placeholder || this.$t('EnterText'),
        toolbar: false,
        quickbars_insert_toolbar: '',
        quickbars_selection_toolbar: 'bold italic underline | hr | forecolor backcolor | fontsize | alignleft aligncenter alignright alignfull | h1 h2 h3  | numlist bullist | blockquote quicklink',
        language: this.TINY_MCE_LANG,
        automatic_uploads: false,
        contextmenu: 'undo redo | inserttable | cell row column deletetable | help',

        setup : this.editorSetup

      }
    },

    editActionsSelect() {
      return [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
  },
  methods : {
    editorSetup(editor) {
      editor.on('init', () => {
        this.editorReady = true
      });
      editor.on('blur', () => {
        this.$emit('input', this.section)
      });
    }
  }
}
</script>

<style scoped>
</style>